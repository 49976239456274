import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import Layout from "../components/layout/layout"
import { joinedPostList } from "../utils/posts"
import Seal from "../components/icons/seal"
import PostListContent from "../components/postList/postListContent"

const Tags = ({
  pageContext,
  data: {
    allContentfulBlogPost: { edges: contentfulEdges },
  },
}) => {
  const { tag } = pageContext
  const isChineseTag = tag === "chinese"

  const posts = joinedPostList(contentfulEdges)

  return (
    <Layout title={tag}>
      {isChineseTag && <Seal />}
      <PostListContent posts={posts} />
    </Layout>
  )
}

Tags.propTypes = {
  pageContext: PropTypes.shape({
    tag: PropTypes.string.isRequired,
  }),
  data: PropTypes.any,
}

export default Tags

export const pageQuery = graphql`
  query($tag: String) {
    allContentfulBlogPost(filter: { tagsList: { in: [$tag] } }) {
      __typename
      edges {
        node {
          ...ContentfulNodeFields
        }
      }
    }
  }
`
