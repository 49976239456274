import React from "react"
import SealSVG from "../../images/icons/seal.svg"

const Seal = () => (
  <div className="seal">
    <SealSVG />
  </div>
)

export default Seal
